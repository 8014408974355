var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("What to notify")]),_c('notification-box',{staticClass:"mb-4",attrs:{"type":"info","flat":true}},[_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Instructions")]),_c('p',[_vm._v(" Please enter what information your attorney(s) must notify you about when exercising a power for financial matters. ")])])]),_c('div',{staticClass:"mb-4"},[_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.financialNotify.what.writtenNotice,"title":"Written notice","text":"My attorney(s) for financial matters must provide written notice that they intend to begin exercising power for a financial matter under this enduring power of attorney before exercising the power for the first time."},on:{"select":function($event){_vm.local.financialNotify.what.writtenNotice =
            !_vm.local.financialNotify.what.writtenNotice}}}),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.financialNotify.what.allRecords,"title":"All financial records and accounts","text":"My attorney(s) for financial matters must provide all financial records and accounts."},on:{"select":function($event){_vm.local.financialNotify.what.allRecords =
            !_vm.local.financialNotify.what.allRecords}}}),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.showAboveAmountTextbox,"title":"Other","text":"My attorney(s) for financial matters must provide records relating to transactions above a nominated amount:"},on:{"select":_vm.toggleShowAboveAmountTextbox}},[_c('v-expand-transition',[(_vm.showAboveAmountTextbox)?_c('div',{staticClass:"mx-4"},[_c('h3',{staticClass:"my-4"},[_vm._v("Please specify the amount:")]),_c('v-text-field',_vm._b({attrs:{"error-messages":_vm.showErrors.aboveAmount && _vm.errorMessages.aboveAmount
                  ? _vm.errorMessages.aboveAmount
                  : null},model:{value:(_vm.local.financialNotify.what.aboveAmount),callback:function ($$v) {_vm.$set(_vm.local.financialNotify.what, "aboveAmount", $$v)},expression:"local.financialNotify.what.aboveAmount"}},'v-text-field',_vm.textField,false))],1):_vm._e()])],1),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.financialNotify.what.assets,"title":"Records and accounts for all assets","text":"My attorney(s) for financial matters must provide records and accounts for all assets including property, investments and vehicles."},on:{"select":function($event){_vm.local.financialNotify.what.assets =
            !_vm.local.financialNotify.what.assets}}}),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.financialNotify.what.summaries,"title":"Summaries of income, expenditure and assets","text":"My attorney(s) for financial matters must provide summaries of income, expenditure and assets."},on:{"select":function($event){_vm.local.financialNotify.what.summaries =
            !_vm.local.financialNotify.what.summaries}}}),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.financialNotify.what.plansAndAdvice,"title":"Financial management plans and advice","text":"My attorney(s) for financial matters must provide copies of financial management plans and financial advice obtained."},on:{"select":function($event){_vm.local.financialNotify.what.plansAndAdvice =
            !_vm.local.financialNotify.what.plansAndAdvice}}}),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.showOtherInformationTextbox,"title":"Other"},on:{"select":_vm.toggleShowOtherInformationTextbox}},[_c('v-expand-transition',[(_vm.showOtherInformationTextbox)?_c('div',{staticClass:"mx-4"},[_c('h3',{staticClass:"my-4"},[_vm._v(" Please specify the information to be provided: ")]),(_vm.showOtherInformationTextbox)?_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.otherInformation && _vm.errorMessages.otherInformation
                  ? _vm.errorMessages.otherInformation
                  : null},model:{value:(_vm.local.financialNotify.what.otherInformation),callback:function ($$v) {_vm.$set(_vm.local.financialNotify.what, "otherInformation", $$v)},expression:"local.financialNotify.what.otherInformation"}},'v-textarea',_vm.textAreaFieldGrow,false)):_vm._e()],1):_vm._e()])],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }