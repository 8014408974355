var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Commencement")]),_c('notification-box',{staticClass:"mb-4",attrs:{"type":"info","flat":true}},[_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Instructions")]),_c('p',[_vm._v(" You may choose when this power of attorney is to commence operation. Click the option that corresponds to when you want this power of attorney to operate. ")])])]),_c('div',{staticClass:"mb-4"},[_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.commencement === 'immediately',"title":"Immediately"},on:{"select":function($event){return _vm.selectCommencementOption('immediately')}}}),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.commencement === 'impaired',"title":"Only when I become a person with impaired decision-making capacity"},on:{"select":function($event){return _vm.selectCommencementOption('impaired')}}}),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.commencement === 'from-date',"title":"From date"},on:{"select":function($event){return _vm.selectCommencementOption('from-date')}}},[_c('v-expand-transition',[(_vm.localFormatted.commencement === 'from-date')?_c('div',{staticClass:"mx-4"},[_c('h3',{staticClass:"my-4"},[_vm._v(" Please specify when this Enduring Power of Attorney is to commence: ")]),_c('date-field',_vm._b({ref:"dateRef",staticStyle:{"margin-bottom":"-1.2rem"},attrs:{"valid":_vm.dateValid,"in-future":true},on:{"update:valid":function($event){_vm.dateValid=$event}}},'date-field',_vm.fromDate,false,true)),_c('v-input',{staticClass:"mb-1",attrs:{"error-messages":_vm.showErrors.commencementDate && _vm.errorMessages.commencementDate
                  ? _vm.errorMessages.commencementDate
                  : null}})],1):_vm._e()])],1),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.commencement === 'from-event',"title":"From event"},on:{"select":function($event){return _vm.selectCommencementOption('from-event')}}},[_c('v-expand-transition',[(_vm.localFormatted.commencement === 'from-event')?_c('div',{staticClass:"mx-4"},[_c('h3',{staticClass:"my-4"},[_vm._v(" Please specify when this Enduring Power of Attorney is to commence: ")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.commencementEvent &&
                _vm.errorMessages.commencementEvent
                  ? _vm.errorMessages.commencementEvent
                  : null},model:{value:(_vm.local.commencementEvent),callback:function ($$v) {_vm.$set(_vm.local, "commencementEvent", $$v)},expression:"local.commencementEvent"}},'v-textarea',_vm.textAreaFieldGrow,false))],1):_vm._e()])],1),_c('v-input',{attrs:{"error-messages":_vm.showErrors.commencement && _vm.errorMessages.commencement
            ? _vm.errorMessages.commencement
            : null}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }