const decisionMakerPredefinedMatters = [
  {
    label: `all matters`,
    key: `allMatters`,
  },
  {
    label: `financial matters (including dealing in property)`,
    key: `financialMatters`,
  },
  {
    label: `personal/health matters`,
    key: `personalMatters`,
  },
]

const decisionsMadeOptions = [
  {
    label: `Severally (any one of them may decide)`,
    key: `severally`,
  },
  {
    label: `Jointly (unanimously)`,
    key: `jointly`,
  },
  {
    label: `Other or specific circumstances`,
    key: `other`,
  },
]

export { decisionMakerPredefinedMatters, decisionsMadeOptions }
