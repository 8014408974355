const appointmentOptions = [
  {
    label: `Jointly`,
    text: `All of my attorneys must agree on all decisions.`,
    key: `jointly`,
  },
  {
    label: `Severally`,
    text: `Any one of my attorneys may decide.`,
    key: `severally`,
  },
  {
    label: `By a majority`,
    text: `More than half of my attorneys must agree on all decisions.`,
    key: `majority`,
  },
  {
    label: `Other`,
    text: `e.g. Jointly and severally, or appointing a successive or alternative attorney.`,
    key: `other`,
  },
]

export { appointmentOptions }
