var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Authority to Someone Else")]),_c('notification-box',{staticClass:"mb-4",attrs:{"type":"info","flat":true}},[_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Instructions")]),_c('p',[_vm._v(" You may authorise your attorney"+_vm._s(_vm.attorneySuffix)+" to authorise someone else to exercise all or some of their powers as your attorney"+_vm._s(_vm.attorneySuffix)+". Choose your option below by initialling in the appropriate box. Cross out and initial or delete any option that does not apply. ")])])]),_c('h3',{staticClass:"mb-4"},[_vm._v(" What authority do you authorise your attorney"+_vm._s(_vm.attorneySuffix)+" to authorise someone else to exercise? ")]),_c('div',{staticClass:"mb-4"},[_vm._l((_vm.authorityOptions.slice(0, -1)),function(item,index){return _c('item-select',{key:item.key,class:{ 'mb-2': index + 1 < _vm.authorityOptions.length },attrs:{"value":item.key === _vm.localFormatted.attorneyAuthoriseOther,"title":item.label,"text":item.text},on:{"select":function($event){return _vm.selectOption(item.key)}}})}),_c('item-select',{key:_vm.otherOption.key,attrs:{"value":_vm.otherOption.key === _vm.localFormatted.attorneyAuthoriseOther,"title":_vm.otherOption.label,"text":_vm.otherOption.text},on:{"select":function($event){return _vm.selectOption(_vm.otherOption.key)}}},[_c('v-expand-transition',[(_vm.otherOption.key === _vm.localFormatted.attorneyAuthoriseOther)?_c('div',{staticClass:"mx-4"},[_c('h3',{staticClass:"my-4"},[_vm._v("Please specify the powers:")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.attorneyAuthoriseOtherSpecified &&
                _vm.errorMessages.attorneyAuthoriseOtherSpecified
                  ? _vm.errorMessages.attorneyAuthoriseOtherSpecified
                  : null},model:{value:(_vm.local.attorneyAuthoriseOtherSpecified),callback:function ($$v) {_vm.$set(_vm.local, "attorneyAuthoriseOtherSpecified", $$v)},expression:"local.attorneyAuthoriseOtherSpecified"}},'v-textarea',_vm.textAreaFieldGrow,false))],1):_vm._e()])],1),_c('v-input',{attrs:{"error-messages":_vm.showErrors.attorneyAuthoriseOther &&
          _vm.errorMessages.attorneyAuthoriseOther
            ? _vm.errorMessages.attorneyAuthoriseOther
            : null}})],2),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }