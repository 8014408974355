const appointmentOptions = [
  {
    label: `Together`,
    key: `together`,
  },
  {
    label: `Separately`,
    key: `separately`,
  },
  {
    label: `Together and Separately`,
    key: `together-and-separately`,
  },
]

function getAuthorityOptions(multiple) {
  return [
    {
      label: `No authority`,
      text: multiple
        ? `My attorneys must not authorise anyone else to exercise my attorneys’
      powers.`
        : `My attorney must not authorise anyone else to exercise my attorney’s powers.`,
      key: `none`,
    },
    {
      label: `Authorise for any power`,
      text: multiple
        ? `My attorneys may authorise one or more people to exercise my attorneys’ powers.`
        : `My attorney may authorise one or more people to exercise my attorney’s powers.`,
      key: `any`,
    },
    {
      label: `Authorise for specific powers`,
      text: multiple
        ? `My attorneys may authorise one or more people to exercise specific powers.`
        : `My attorney may authorise one or more people to exercise specific powers.`,
      key: `specified`,
    },
  ]
}

function getRefusalOptions(multiple) {
  return [
    {
      label: `Must not refuse treatment`,
      text: multiple
        ? `My attorneys must not refuse, or require the withdrawal of, medical treatment on
        my behalf.`
        : `My attorney must not refuse, or require the withdrawal of, medical treatment on
        my behalf.`,
      key: `must-not`,
    },
    {
      label: `Generally refuse treatment`,
      text: multiple
        ? `My attorneys may, on my behalf - refuse, or require the withdrawal of, medical treatment generally.`
        : `My attorney may, on my behalf - refuse, or require the withdrawal of, medical treatment generally.`,
      key: `may-generally`,
    },
    {
      label: `Refuse specified treatments`,
      text: multiple
        ? `My attorneys may, on my behalf - refuse, or require the withdrawal of, specified kinds of medical treatment.`
        : `My attorney may, on my behalf - refuse, or require the withdrawal of, specified kinds of medical treatment.`,
      key: `some`,
    },
  ]
}

export { appointmentOptions, getAuthorityOptions, getRefusalOptions }
