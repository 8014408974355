var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("When to notify for financial matters")]),_c('notification-box',{staticClass:"mb-4",attrs:{"type":"info","flat":true}},[_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Instructions")]),_c('p',[_vm._v(" Please select each option that you would like to be notified about when it occurs. ")])])]),_c('div',{staticClass:"mb-4"},[_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.localFormatted.financialNotify.when.onRequest,"title":"On request","text":"My attorney(s) must give the information on request at any time by the nominated person."},on:{"select":function($event){_vm.local.financialNotify.when.onRequest =
            !_vm.local.financialNotify.when.onRequest}}}),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.showTimeframeTextbox,"title":"On timeframe","text":"My attorney(s) must give the information on a regular timeframe."},on:{"select":_vm.toggleShowTimeframeTextbox}},[_c('v-expand-transition',[(_vm.showTimeframeTextbox)?_c('div',{staticClass:"mx-4"},[_c('h3',{staticClass:"my-4"},[_vm._v("Please state the timeframe:")]),_c('p',[_c('i',[_vm._v(" e.g. annually on 1 July of each year or quarterly starting on 1 July ")])]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.onTimeframe && _vm.errorMessages.onTimeframe
                  ? _vm.errorMessages.onTimeframe
                  : null},model:{value:(_vm.local.financialNotify.when.onTimeframe),callback:function ($$v) {_vm.$set(_vm.local.financialNotify.when, "onTimeframe", $$v)},expression:"local.financialNotify.when.onTimeframe"}},'v-textarea',_vm.textAreaFieldGrow,false))],1):_vm._e()])],1),_c('item-select',{staticClass:"mb-2",attrs:{"value":_vm.showOtherTextbox,"title":"Other"},on:{"select":_vm.toggleShowOtherTextbox}},[_c('v-expand-transition',[(_vm.showOtherTextbox)?_c('div',{staticClass:"mx-4"},[_c('h3',{staticClass:"my-4"},[_vm._v("Please specify:")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.onOther && _vm.errorMessages.onOther
                  ? _vm.errorMessages.onOther
                  : null},model:{value:(_vm.local.financialNotify.when.onOther),callback:function ($$v) {_vm.$set(_vm.local.financialNotify.when, "onOther", $$v)},expression:"local.financialNotify.when.onOther"}},'v-textarea',_vm.textAreaFieldGrow,false))],1):_vm._e()])],1),_c('v-input',{attrs:{"error-messages":_vm.showErrors.commencement && _vm.errorMessages.commencement
            ? _vm.errorMessages.commencement
            : null}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }