var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Functions and limits")]),_c('notification-box',{staticClass:"mb-4",attrs:{"type":"info","flat":true}},[_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Instructions")]),_c('p',[_vm._v(" Select what functions you wish your Enduring Guardian"+_vm._s(_vm.guardianSuffix)+" to have should you lose capacity to make decisions yourself. ")])])]),_c('item-select',{ref:"livingSituationSection",staticClass:"mb-4",attrs:{"value":_vm.local.functions.livingSituation,"title":"Decide where I live"},on:{"select":function($event){_vm.local.functions.livingSituation = !_vm.local.functions.livingSituation}}},[_c('v-expand-transition',[(_vm.local.functions.livingSituation)?_c('div',{staticClass:"mx-4"},[_c('h4',{staticClass:"my-4"},[_vm._v(" Would you like to place any limits on this function? ")]),_c('div',{staticClass:"toggle-yes-no"},[_c('item-select',{attrs:{"value":_vm.limitFlags.livingSituation,"title":"Yes"},on:{"select":function($event){return _vm.toggleFlag('livingSituation', true)}}}),_c('item-select',{attrs:{"value":!_vm.limitFlags.livingSituation,"title":"No"},on:{"select":function($event){return _vm.toggleFlag('livingSituation', false)}}})],1),(_vm.limitFlags.livingSituation)?_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.livingSituation && _vm.errorMessages.livingSituation
                ? _vm.errorMessages.livingSituation
                : null},model:{value:(_vm.local.functions.livingSituationDetails),callback:function ($$v) {_vm.$set(_vm.local.functions, "livingSituationDetails", $$v)},expression:"local.functions.livingSituationDetails"}},'v-textarea',_vm.textAreaFieldGrow,false)):_vm._e()],1):_vm._e()])],1),_c('item-select',{ref:"healthCareSection",staticClass:"mb-4",attrs:{"value":_vm.local.functions.healthCare,"title":"Decide what health care I receive"},on:{"select":function($event){_vm.local.functions.healthCare = !_vm.local.functions.healthCare}}},[_c('v-expand-transition',[(_vm.local.functions.healthCare)?_c('div',{staticClass:"mx-4"},[_c('h4',{staticClass:"my-4"},[_vm._v(" Would you like to place any limits on this function? ")]),_c('div',{staticClass:"toggle-yes-no"},[_c('item-select',{attrs:{"value":_vm.limitFlags.healthCare,"title":"Yes"},on:{"select":function($event){return _vm.toggleFlag('healthCare', true)}}}),_c('item-select',{attrs:{"value":!_vm.limitFlags.healthCare,"title":"No"},on:{"select":function($event){return _vm.toggleFlag('healthCare', false)}}})],1),(_vm.limitFlags.healthCare)?_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.healthCare && _vm.errorMessages.healthCare
                ? _vm.errorMessages.healthCare
                : null},model:{value:(_vm.local.functions.healthCareDetails),callback:function ($$v) {_vm.$set(_vm.local.functions, "healthCareDetails", $$v)},expression:"local.functions.healthCareDetails"}},'v-textarea',_vm.textAreaFieldGrow,false)):_vm._e()],1):_vm._e()])],1),_c('item-select',{ref:"personalServicesSection",staticClass:"mb-4",attrs:{"value":_vm.local.functions.personalServices,"title":"Decide what other kinds of personal services I receive"},on:{"select":function($event){_vm.local.functions.personalServices = !_vm.local.functions.personalServices}}},[_c('v-expand-transition',[(_vm.local.functions.personalServices)?_c('div',{staticClass:"mx-4"},[_c('h4',{staticClass:"my-4"},[_vm._v(" Would you like to place any limits on this function? ")]),_c('div',{staticClass:"toggle-yes-no"},[_c('item-select',{attrs:{"value":_vm.limitFlags.personalServices,"title":"Yes"},on:{"select":function($event){return _vm.toggleFlag('personalServices', true)}}}),_c('item-select',{attrs:{"value":!_vm.limitFlags.personalServices,"title":"No"},on:{"select":function($event){return _vm.toggleFlag('personalServices', false)}}})],1),(_vm.limitFlags.personalServices)?_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.personalServices && _vm.errorMessages.personalServices
                ? _vm.errorMessages.personalServices
                : null},model:{value:(_vm.local.functions.personalServicesDetails),callback:function ($$v) {_vm.$set(_vm.local.functions, "personalServicesDetails", $$v)},expression:"local.functions.personalServicesDetails"}},'v-textarea',_vm.textAreaFieldGrow,false)):_vm._e()],1):_vm._e()])],1),_c('item-select',{ref:"medicalDentalSection",staticClass:"mb-4",attrs:{"value":_vm.local.functions.medicalDental,"title":"Consent to the carrying out of medical or dental treatment on me"},on:{"select":function($event){_vm.local.functions.medicalDental = !_vm.local.functions.medicalDental}}},[_c('div',{staticClass:"pl-14 pr-4 pb-4"},[_vm._v(" (in accordance with Part 5 of the "),_c('a',{attrs:{"href":"https://legislation.nsw.gov.au/view/html/inforce/current/act-1987-257#pt.5","target":"_blank"}},[_c('i',[_vm._v("Guardianship Act 1987")])]),_vm._v(") ")]),_c('v-expand-transition',[(_vm.local.functions.medicalDental)?_c('div',{staticClass:"mx-4"},[_c('h4',{staticClass:"my-4"},[_vm._v(" Would you like to place any limits on this function? ")]),_c('div',{staticClass:"toggle-yes-no"},[_c('item-select',{attrs:{"value":_vm.limitFlags.medicalDental,"title":"Yes"},on:{"select":function($event){return _vm.toggleFlag('medicalDental', true)}}}),_c('item-select',{attrs:{"value":!_vm.limitFlags.medicalDental,"title":"No"},on:{"select":function($event){return _vm.toggleFlag('medicalDental', false)}}})],1),(_vm.limitFlags.medicalDental)?_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.medicalDental && _vm.errorMessages.medicalDental
                ? _vm.errorMessages.medicalDental
                : null},model:{value:(_vm.local.functions.medicalDentalDetails),callback:function ($$v) {_vm.$set(_vm.local.functions, "medicalDentalDetails", $$v)},expression:"local.functions.medicalDentalDetails"}},'v-textarea',_vm.textAreaFieldGrow,false)):_vm._e()],1):_vm._e()])],1),_c('item-select',{ref:"otherSection",staticClass:"mb-4",attrs:{"value":_vm.limitFlags.other,"title":"Other"},on:{"select":function($event){return _vm.toggleFlag('other', !_vm.limitFlags.other)}}},[_c('v-expand-transition',[(_vm.limitFlags.other)?_c('div',{staticClass:"mx-4"},[_c('div',{staticClass:"my-4"},[_vm._v(" An example of other functions may include the ability to decide with whom you should have contact and who may visit you. ")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.other && _vm.errorMessages.other
                ? _vm.errorMessages.other
                : null},model:{value:(_vm.local.functions.other),callback:function ($$v) {_vm.$set(_vm.local.functions, "other", $$v)},expression:"local.functions.other"}},'v-textarea',_vm.textAreaFieldGrow,false))],1):_vm._e()])],1),_c('v-input',{attrs:{"error-messages":_vm.showErrors.functions && _vm.errorMessages.functions
          ? _vm.errorMessages.functions
          : null}}),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }