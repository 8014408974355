var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Functions and limitations")]),_c('notification-box',{staticClass:"mb-4",attrs:{"type":"info","flat":true}},[_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Instructions")]),_c('p',[_vm._v(" Please select the functions you wish to authorise your attorney"+_vm._s(_vm.attorneySuffix)+" to do on your behalf. ")])])]),_c('item-select',{ref:"propertySection",staticClass:"mb-4",attrs:{"value":_vm.local.functions.property,"title":"Property matters (includes financial matters)"},on:{"select":function($event){return _vm.toggleLocalFunction('property')}}},[_c('v-expand-transition',[(_vm.local.functions.property)?_c('div',{staticClass:"mx-4"},[_c('h4',{staticClass:"my-4"},[_vm._v(" Would you like to place directions, limitations and conditions on this power? ")]),_c('div',{staticClass:"toggle-yes-no"},[_c('item-select',{attrs:{"value":_vm.limitFlags.property,"title":"Yes"},on:{"select":function($event){return _vm.setLimitFlag('property', true)}}}),_c('item-select',{attrs:{"value":!_vm.limitFlags.property,"title":"No"},on:{"select":function($event){return _vm.setLimitFlag('property', false)}}})],1),(_vm.limitFlags.property)?_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.property && _vm.errorMessages.property
                ? _vm.errorMessages.property
                : null},model:{value:(_vm.local.functions.propertyConditions),callback:function ($$v) {_vm.$set(_vm.local.functions, "propertyConditions", $$v)},expression:"local.functions.propertyConditions"}},'v-textarea',_vm.textAreaFieldGrow,false)):_vm._e()],1):_vm._e()])],1),_c('item-select',{ref:"personalCareSection",staticClass:"mb-4",attrs:{"value":_vm.local.functions.personalCare,"title":"Personal care matters"},on:{"select":function($event){return _vm.toggleLocalFunction('personalCare')}}},[_c('v-expand-transition',[(_vm.local.functions.personalCare)?_c('div',{staticClass:"mx-4"},[_c('h4',{staticClass:"my-4"},[_vm._v(" Would you like to place directions, limitations and conditions on this power? ")]),_c('div',{staticClass:"toggle-yes-no"},[_c('item-select',{attrs:{"value":_vm.limitFlags.personalCare,"title":"Yes"},on:{"select":function($event){return _vm.setLimitFlag('personalCare', true)}}}),_c('item-select',{attrs:{"value":!_vm.limitFlags.personalCare,"title":"No"},on:{"select":function($event){return _vm.setLimitFlag('personalCare', false)}}})],1),(_vm.limitFlags.personalCare)?_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.personalCare && _vm.errorMessages.personalCare
                ? _vm.errorMessages.personalCare
                : null},model:{value:(_vm.local.functions.personalCareConditions),callback:function ($$v) {_vm.$set(_vm.local.functions, "personalCareConditions", $$v)},expression:"local.functions.personalCareConditions"}},'v-textarea',_vm.textAreaFieldGrow,false)):_vm._e()],1):_vm._e()])],1),_c('item-select',{ref:"healthCareSection",staticClass:"mb-4",attrs:{"value":_vm.local.functions.healthCare,"title":"Health care matters"},on:{"select":function($event){return _vm.toggleLocalFunction('healthCare')}}},[_c('v-expand-transition',[(_vm.local.functions.healthCare)?_c('div',{staticClass:"mx-4"},[_c('h4',{staticClass:"my-4"},[_vm._v(" Would you like to place directions, limitations and conditions on this power? ")]),_c('div',{staticClass:"toggle-yes-no"},[_c('item-select',{attrs:{"value":_vm.limitFlags.healthCare,"title":"Yes"},on:{"select":function($event){return _vm.setLimitFlag('healthCare', true)}}}),_c('item-select',{attrs:{"value":!_vm.limitFlags.healthCare,"title":"No"},on:{"select":function($event){return _vm.setLimitFlag('healthCare', false)}}})],1),(_vm.limitFlags.healthCare)?_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.healthCare && _vm.errorMessages.healthCare
                ? _vm.errorMessages.healthCare
                : null},model:{value:(_vm.local.functions.healthCareConditions),callback:function ($$v) {_vm.$set(_vm.local.functions, "healthCareConditions", $$v)},expression:"local.functions.healthCareConditions"}},'v-textarea',_vm.textAreaFieldGrow,false)):_vm._e()],1):_vm._e()])],1),_c('item-select',{ref:"medicalResearchSection",staticClass:"mb-4",attrs:{"value":_vm.local.functions.medicalResearch,"title":"Medical research matters"},on:{"select":function($event){return _vm.toggleLocalFunction('medicalResearch')}}},[_c('v-expand-transition',[(_vm.local.functions.medicalResearch)?_c('div',{staticClass:"mx-4"},[_c('h4',{staticClass:"my-4"},[_vm._v(" Would you like to place directions, limitations and conditions on this power? ")]),_c('div',{staticClass:"toggle-yes-no"},[_c('item-select',{attrs:{"value":_vm.limitFlags.medicalResearch,"title":"Yes"},on:{"select":function($event){return _vm.setLimitFlag('medicalResearch', true)}}}),_c('item-select',{attrs:{"value":!_vm.limitFlags.medicalResearch,"title":"No"},on:{"select":function($event){return _vm.setLimitFlag('medicalResearch', false)}}})],1),(_vm.limitFlags.medicalResearch)?_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.medicalResearch && _vm.errorMessages.medicalResearch
                ? _vm.errorMessages.medicalResearch
                : null},model:{value:(_vm.local.functions.medicalResearchConditions),callback:function ($$v) {_vm.$set(_vm.local.functions, "medicalResearchConditions", $$v)},expression:"local.functions.medicalResearchConditions"}},'v-textarea',_vm.textAreaFieldGrow,false)):_vm._e()],1):_vm._e()])],1),_c('v-input',{attrs:{"error-messages":_vm.showErrors.functions && _vm.errorMessages.functions
          ? _vm.errorMessages.functions
          : null}}),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }