var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Refusal or withdrawal of medical treatment")]),_c('notification-box',{staticClass:"mb-4",attrs:{"type":"info","flat":true}},[_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Instructions")]),_c('p',[_vm._v("Please select the option that applies.")])])]),_c('div',{staticClass:"mb-4"},[_vm._l((_vm.refusalOptions.slice(0, -1)),function(item,index){return _c('item-select',{key:item.key,class:{ 'mb-2': index + 1 < _vm.refusalOptions.length },attrs:{"value":item.key === _vm.localFormatted.refuseMedicalTreatment,"title":item.label,"text":item.text},on:{"select":function($event){return _vm.selectOption(item.key)}}})}),_c('item-select',{key:_vm.otherOption.key,attrs:{"value":_vm.otherOption.key === _vm.localFormatted.refuseMedicalTreatment,"title":_vm.otherOption.label,"text":_vm.otherOption.text},on:{"select":function($event){return _vm.selectOption(_vm.otherOption.key)}}},[_c('v-expand-transition',[(_vm.otherOption.key === _vm.localFormatted.refuseMedicalTreatment)?_c('div',{staticClass:"mx-4"},[_c('h3',{staticClass:"my-4"},[_vm._v("Please specify the medical treatments:")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.refuseMedicalTreatmentSome &&
                _vm.errorMessages.refuseMedicalTreatmentSome
                  ? _vm.errorMessages.refuseMedicalTreatmentSome
                  : null},model:{value:(_vm.local.refuseMedicalTreatmentSome),callback:function ($$v) {_vm.$set(_vm.local, "refuseMedicalTreatmentSome", $$v)},expression:"local.refuseMedicalTreatmentSome"}},'v-textarea',_vm.textAreaFieldGrow,false))],1):_vm._e()])],1),_c('v-input',{attrs:{"error-messages":_vm.showErrors.refuseMedicalTreatment &&
          _vm.errorMessages.refuseMedicalTreatment
            ? _vm.errorMessages.refuseMedicalTreatment
            : null}})],2),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }