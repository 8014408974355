var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("What is important to you")]),_c('h3',{staticClass:"mb-4"},[_vm._v(" When decisions are being made for you, what do you want the decision-makers to consider? ")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"dense":true,"error-messages":_vm.showErrors.considerations && _vm.errorMessages.considerations
          ? _vm.errorMessages.considerations
          : null},model:{value:(_vm.local.importantToMe.considerations),callback:function ($$v) {_vm.$set(_vm.local.importantToMe, "considerations", $$v)},expression:"local.importantToMe.considerations"}},'v-textarea',_vm.textAreaFieldShort,false)),_c('h3',{staticClass:"mb-4"},[_vm._v("What are the outcomes of care you wish to avoid?")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.avoidOutcomes && _vm.errorMessages.avoidOutcomes
          ? _vm.errorMessages.avoidOutcomes
          : null},model:{value:(_vm.local.importantToMe.avoidOutcomes),callback:function ($$v) {_vm.$set(_vm.local.importantToMe, "avoidOutcomes", $$v)},expression:"local.importantToMe.avoidOutcomes"}},'v-textarea',_vm.textAreaFieldShort,false)),_c('h3',{staticClass:"mb-4"},[_vm._v("What health care do you prefer?")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.preferredHealthCare && _vm.errorMessages.preferredHealthCare
          ? _vm.errorMessages.preferredHealthCare
          : null},model:{value:(_vm.local.importantToMe.preferredHealthCare),callback:function ($$v) {_vm.$set(_vm.local.importantToMe, "preferredHealthCare", $$v)},expression:"local.importantToMe.preferredHealthCare"}},'v-textarea',_vm.textAreaFieldShort,false)),_c('h3',{staticClass:"mb-4"},[_vm._v("Where do you wish to live?")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.whereToLive && _vm.errorMessages.whereToLive
          ? _vm.errorMessages.whereToLive
          : null},model:{value:(_vm.local.importantToMe.whereToLive),callback:function ($$v) {_vm.$set(_vm.local.importantToMe, "whereToLive", $$v)},expression:"local.importantToMe.whereToLive"}},'v-textarea',_vm.textAreaFieldShort,false)),_c('h3',{staticClass:"mb-4"},[_vm._v("Please list other personal arrangements:")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.personalArrangements && _vm.errorMessages.personalArrangements
          ? _vm.errorMessages.personalArrangements
          : null},model:{value:(_vm.local.importantToMe.personalArrangements),callback:function ($$v) {_vm.$set(_vm.local.importantToMe, "personalArrangements", $$v)},expression:"local.importantToMe.personalArrangements"}},'v-textarea',_vm.textAreaFieldShort,false)),_c('h3',{staticClass:"mb-4"},[_vm._v("What are your dying wishes?")]),_c('v-textarea',_vm._b({attrs:{"rules":[_vm.maxLengthRule],"error-messages":_vm.showErrors.dyingWishes && _vm.errorMessages.dyingWishes
          ? _vm.errorMessages.dyingWishes
          : null},model:{value:(_vm.local.importantToMe.dyingWishes),callback:function ($$v) {_vm.$set(_vm.local.importantToMe, "dyingWishes", $$v)},expression:"local.importantToMe.dyingWishes"}},'v-textarea',_vm.textAreaFieldShort,false)),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }