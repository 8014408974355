var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.local)?_c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Guardians")]),_c('notification-box',{staticClass:"mb-4",attrs:{"type":"info","flat":true}},[_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Instructions")]),_c('p',[_vm._v("Add up to 4 people as your guardians.")])])]),_c('h3',{staticClass:"mb-4"},[_vm._v("Your guardians:")]),_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(!_vm.localFormatted.guardians.length)?_c('div',{key:"empty",staticClass:"text-no-result"},[_vm._v(" - Add at least one guardian - ")]):_c('div',{key:"list"},[_c('transition-group',{attrs:{"name":"component-fade","mode":"out-in"}},_vm._l((_vm.localFormatted.guardians),function(item,index){return _c('item-person',{key:item + index,staticClass:"mb-2",attrs:{"id":item,"editable":_vm.editable,"type":"person"},on:{"remove":function($event){return _vm.removeGuardian(index)}},scopedSlots:_vm._u([{key:"details",fn:function({ entity }){return [(entity.type === 'person')?_c('div',{staticClass:"text-sm text-cutoff opacity-70 pt-1"},[(!entity.address)?_c('span',{staticClass:"font-italic"},[_vm._v("- Address Required -")]):_c('span',[_vm._v(" "+_vm._s(entity.address)+" ")])]):_vm._e()]}}],null,true)})}),1)],1)]),_c('div',{staticClass:"text-label my-4"},[_vm._v("Add a guardian:")]),_c('div',{staticClass:"mb-2"},[_c('btn-add-large',{staticClass:"mb-2",attrs:{"label":"Add person","disabled":_vm.isMaximumSelected},on:{"click":function($event){_vm.openDrawerPerson = true},"clickDisabled":function($event){return _vm.disabledMessage()}}})],1),_c('v-input',{attrs:{"error-messages":_vm.showErrors.guardians && _vm.errorMessages.guardians
          ? _vm.errorMessages.guardians
          : null}}),_c('v-expand-transition',[(this.local.guardians.length > 1)?_c('div',{staticClass:"mb-4"},[_c('h2',{staticClass:"mb-4"},[_vm._v(" How would you like to your guardians to work together? ")]),_vm._l((_vm.appointmentOptions),function(item,index){return _c('item-select',{key:item.key,class:{ 'mb-2': index + 1 < _vm.appointmentOptions.length },attrs:{"value":item.key === _vm.localFormatted.guardiansAppointment,"title":item.label,"text":item.text},on:{"select":function($event){return _vm.selectAppointmentOption(item.key)}}})}),_c('v-input',{attrs:{"error-messages":_vm.showErrors.guardiansAppointment &&
            _vm.errorMessages.guardiansAppointment
              ? _vm.errorMessages.guardiansAppointment
              : null}})],2):_vm._e()]),_c('v-expand-transition',[(this.local.guardians.length > 1)?_c('div',{staticClass:"mb-4"},[_c('h2',{staticClass:"mb-4"},[_vm._v(" If one or more of my joint enduring guardians die: ")]),_vm._l((_vm.appointmentTerminationOptions),function(item,index){return _c('item-select',{key:item.key,class:{
            'mb-2': index + 1 < _vm.appointmentTerminationOptions.length,
          },attrs:{"value":item.key === _vm.localFormatted.appointmentTermination,"title":item.label,"text":item.text},on:{"select":function($event){return _vm.selectAppointmentTerminationOption(item.key)}}})}),_c('v-input',{attrs:{"error-messages":_vm.showErrors.appointmentTermination &&
            _vm.errorMessages.appointmentTermination
              ? _vm.errorMessages.appointmentTermination
              : null}})],2):_vm._e()]),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v(_vm._s(_vm.submitLabel))])],1)],1):_vm._e(),_c('drawer',{attrs:{"open":_vm.openDrawerPerson,"title":"Add guardian"},on:{"update:open":function($event){_vm.openDrawerPerson=$event}}},[(_vm.openDrawerPerson)?_c('drawer-person-select',{attrs:{"exclude":_vm.excludePeople,"editable":{ ..._vm.editable, name: false },"mandatory":['address']},on:{"select":_vm.addGuardian}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }